var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "heading2" }, [
    _c(
      "span",
      { class: { "with-go-back": _vm.withGoBack }, on: { click: _vm.goBack } },
      [
        _vm.withGoBack
          ? _c("svg-icon", {
              staticClass: "btn",
              attrs: { "icon-class": "back" },
            })
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }