var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.hash.length > 12
        ? _c("el-tooltip", { attrs: { content: _vm.hash, placement: "top" } }, [
            _c("span", [_vm._v(_vm._s(_vm._f("filterAddress")(_vm.hash)))]),
          ])
        : [_vm._v(" " + _vm._s(_vm.hash) + " ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }