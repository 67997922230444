var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    _vm._g(
      _vm._b(
        { attrs: { disabled: this.remaining !== 0, loading: _vm.loading } },
        "el-button",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    ),
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }