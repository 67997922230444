var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "copy-wrapper" },
    [
      _c("span", [_vm._t("default")], 2),
      _vm.raw
        ? [
            _c(
              "span",
              {
                staticStyle: {
                  position: "absolute",
                  left: "-99999px",
                  top: "-99999px",
                },
              },
              [_vm._v(_vm._s(_vm.raw))]
            ),
          ]
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "copy-btn",
          on: {
            click: function ($event) {
              return _vm.copy()
            },
            mouseenter: _vm.enter,
            mouseleave: _vm.leave,
          },
        },
        [
          _c(
            "el-tooltip",
            {
              attrs: { content: _vm.tip, placement: "top", manual: "" },
              model: {
                value: _vm.visibility,
                callback: function ($$v) {
                  _vm.visibility = $$v
                },
                expression: "visibility",
              },
            },
            [
              _c("svg-icon", {
                class: { [`f-${_vm.size}`]: true, "ml-20": true },
                attrs: { "icon-class": _vm.icon },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }