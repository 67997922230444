var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { title: "文件预览" } }, [
    _c(
      "div",
      { staticClass: "img" },
      [
        !_vm.isPdf
          ? _c(
              "el-image",
              {
                attrs: {
                  src: _vm.url,
                  "preview-src-list": [_vm.url],
                  fit: "contain",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline f-48" })]
                ),
              ]
            )
          : _c("div", { staticClass: "pdf", on: { click: _vm.download } }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.url,
                    download: _vm.name,
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mb-10",
                    attrs: {
                      src: require("@/assets/images/pdf.svg"),
                      width: "48",
                      height: "48",
                    },
                  }),
                  _c("div", { staticClass: "f-12" }, [
                    _vm._v(_vm._s(_vm._f("filterWordCount")(_vm.name, 12))),
                  ]),
                ]
              ),
            ]),
      ],
      1
    ),
    _vm.tip
      ? _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }